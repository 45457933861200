<template>
  <div class="orderTwolist">
    <van-nav-bar title="我的订单" left-arrow @click-left="onClickLeft" />
    <van-tabs
      v-model="orderactive"
      sticky
      swipe-threshold="5"
      title-active-color="#ee0a24"
      @click="tabsClick"
    >
      <van-tab :style="'min-height:' + (screeHeight - 100) + 'px;'" title="售后订单">
        <div class="main" v-if="list.length > 0 ? true: false">
          <van-list
            v-model="loading[orderactive]"
            :finished="finished[orderactive]"
            finished-text="没有更多了"
            @load="onLoad()"
          >
            <div class="order_list" v-for="(item, index) in list" :key="index">
              <van-cell :border="false">
                <template #title>
                  {{item.ordersn}}
                  <input type="text" v-model="item.ordersn" v-show="false" />
                  <van-button
                    v-clipboard:copy="item.ordersn"
                    v-clipboard:success="ordersnonCopy"
                    v-clipboard:error="ordersnError"
                    size="mini"
                  >
                    复制
                  </van-button>
                </template>
                <template #right-icon>
                  <span class="red">{{ item.status_str }}</span>
                </template>
              </van-cell>
              <div v-if="item.goods && item.goods.length > 0">
                <van-card
                  v-for="(v,k) in item.goods"
                  :key="k"
                  :num="v.total"
                  :price="v.realprice"
                  :title="v.goodstitle"
                  :thumb="v.goodsthumb"
                  @click="details(index, k)"
                  lazy-load
                >
                  <template #desc>
                    <div>
                      {{v.optionname}}
                      <van-tag color="#9168ed" text-color="#fff" v-if="v.yushouTime">{{v.yushouTime}}</van-tag>
                    </div>
                  </template>
                  <template #tag>
                    {{strId}}
                  </template>
                </van-card>
              </div>
              <van-cell title>
                <template #right-icon>实付：&yen;{{item.price}}({{ item.dispatchprice > 0 ? "运费：" + item.dispatchprice + "元" : "免运费" }})</template>
              </van-cell>
              <van-cell :border="false" title="" v-if="item.status > 0">
                <template #right-icon>
                  <van-button size="small" plain hairline v-if="item.is_service_btn" @click="reFund(index)">{{item.is_service_btn}}</van-button>
                  <van-button size="small" plain hairline v-if="item.is_service_btn2" @click="cancelRefund(index)">{{item.is_service_btn2}}</van-button>
                  <van-button size="small" plain hairline type="danger" @click="toGoods(item.goods[0].goodsid, index)">再来一单</van-button>
                </template>
              </van-cell>
              <van-cell
                title-class="red"
                :border="false"
                v-if="item.is_saleservice_content && item.is_saleservice_content.length > 0"
                is-link
                @click="serviceDetail(item.id)"
              >
                <template #title>
                  售后内容 {{item.is_saleservice_content ? item.is_saleservice_content : ''}}
                  <van-tag class="copay_icon" v-if="item.is_saleservice > 0 && item.is_saleservice != 2" plain @click="addserviceInfo(index)">{{(item.rtype == 2 || item.rtype == 1 || item.rtype == 3) && item.progress == 3 ? '添加单号': '回复'}}</van-tag>
                </template>
                <template #default>
                  <div>查看</div>
                </template>
              </van-cell>
              <van-cell :border="false" v-if="item.expresssn" :title="'快递单号：' + item.expresssn">
                <template #right-icon>
                  <input type="text" v-model="item.expresssn" v-show="false" />
                  <van-button
                    size="small"
                    v-clipboard:copy="item.expresssn"
                    v-clipboard:success="expressnonCopy"
                    v-clipboard:error="expressnonError"
                  >复制</van-button>
                </template>
              </van-cell>
              <div class="kefu_coucst" v-if="merchid == 0 || showkefu == 1">
                <van-button
                  block
                  icon="service-o"
                  @click="qiyukefu(index)"
                >
                  联系客服
                </van-button>
              </div>
            </div>
          </van-list>
        </div>
        <div class="main" v-if="list.length <= 0 ? true: false">
          <van-empty description="暂无订单" />
        </div>
      </van-tab>
      <van-tab :style="'min-height:' + (screeHeight - 100) + 'px;'" title="需退款">
        <div class="main" v-if="list.length > 0 ? true: false">
          <van-list
            v-model="loading[orderactive]"
            :finished="finished[orderactive]"
            finished-text="没有更多了"
            @load="onLoad()"
          >
            <div class="order_list" v-for="(item, index) in list" :key="index">
              <van-cell :border="false">
                <template #title>
                  {{item.ordersn}}
                  <input type="text" v-model="item.ordersn" v-show="false" />
                  <van-button
                    v-clipboard:copy="item.ordersn"
                    v-clipboard:success="ordersnonCopy"
                    v-clipboard:error="ordersnError"
                    size="mini"
                  >
                    复制
                  </van-button>
                </template>
                <template #right-icon>
                  <span class="red">{{ item.status_str }}</span>
                </template>
              </van-cell>
              <div v-if="item.goods && item.goods.length > 0">
                <van-card
                  v-for="(v,k) in item.goods"
                  :key="k"
                  :num="v.total"
                  :price="v.realprice"
                  :title="v.goodstitle"
                  :thumb="v.goodsthumb"
                  @click="details(index, k)"
                  lazy-load
                >
                  <template #desc>
                    {{v.optionname ? v.optionname : ""}}
                    <van-tag color="#9168ed" text-color="#fff" v-if="v.yushouTime">{{v.yushouTime}}</van-tag>
                  </template>
                  <template #tag>
                    {{strId}}
                  </template>
                </van-card>
              </div>
              <van-cell title>
                <template #right-icon>实付：&yen;{{item.price}}({{ item.dispatchprice > 0 ? "运费：" + item.dispatchprice + "元" : "免运费" }})</template>
              </van-cell>
              <van-cell :border="false" title="">
                <template #right-icon>
                  <van-button
                    size="small"
                    plain
                    hairline
                    @click="reFundHistory(item.id)"
                    v-if="item.bc_list_btn == 1"
                  >退款记录</van-button>
                  <van-button
                    size="small"
                    plain
                    hairline
                    @click="miniTuikuan(index)"
                    v-if="item.bc_go_btn == 1"
                  >{{item.is_saleservice > 0 && item.is_saleservice != 2 ? '退款中' : '申请退款'}}</van-button>
                  <van-button
                    size="small"
                    v-if="item.bc_go_btn == 2"
                    plain
                    hairline
                    @click="reFund(index)"
                  >{{item.is_saleservice > 0 && item.is_saleservice != 2 ? '售后中' : '申请售后'}}</van-button>
                  <van-button size="small" plain hairline v-if="item.refundstate==0 && item.status > 0" @click="reFund(index)">申请退款</van-button>
                  <van-button size="small" plain hairline v-if="item.refundstate==1 && item.status > 0" @click="cancelRefund(index)">撤销退款</van-button>
                  <van-button size="small" plain hairline type="danger" @click="toGoods(item.goods[0].goodsid, index)">再来一单</van-button>
                </template>
              </van-cell>
              <van-cell
                title-class="red"
                :border="false"
                v-if="item.is_saleservice_content && item.is_saleservice_content.length > 0"
                is-link
                @click="serviceDetail(item.id)"
              >
                <template #title>
                  售后内容 {{item.is_saleservice_content ? item.is_saleservice_content : ''}}
                  <van-tag class="copay_icon" v-if="item.is_saleservice > 0 && item.is_saleservice != 2" plain @click="addserviceInfo(index)">{{(item.rtype == 2 || item.rtype == 1 || item.rtype == 3) && item.progress == 3 ? '添加单号': '回复'}}</van-tag>
                </template>
                <template #default>
                  <div>查看</div>
                </template>
              </van-cell>
              <van-cell title-class="red">
                <template #title>
                  请退款{{item.special_remark ? "：" + item.special_remark : ""}}
                </template>
              </van-cell>
              <van-cell v-if="item.expresssn" :title="'快递单号：' + item.expresssn">
                <template #right-icon>
                  <input type="text" v-model="item.expresssn" v-show="false" />
                  <van-button
                    size="small"
                    v-clipboard:copy="item.expresssn"
                    v-clipboard:success="expressnonCopy"
                    v-clipboard:error="expressnonError"
                  >复制</van-button>
                </template>
              </van-cell>
              <div class="kefu_coucst" v-if="merchid == 0 || showkefu == 1">
                <van-button
                  block
                  icon="service-o"
                  @click="qiyukefu(index)"
                >
                  联系客服
                </van-button>
              </div>
            </div>
          </van-list>
        </div>
        <div class="main" v-if="list.length <= 0 ? true: false">
          <van-empty description="暂无订单" />
        </div>
      </van-tab>
    </van-tabs>
    <van-dialog
      use-slot
      title="退回商品"
      v-model="showAddinfo"
      show-cancel-button
      @confirm="btnConfirm"
      @cancel="btnCancel"
    >
      <div class="input_main">
        <van-field
          v-model="rexpresssn"
          clearable
          placeholder="请输入退货单号"
          :border="false"
          input-align="center"
        />
      </div>
    </van-dialog>
  </div>
</template>
<script>
export default {
  name: "OrderTwoList",
  data() {
    return {
      screenWidth: document.body.clientWidth, // 屏幕宽
      screeHeight: document.body.clientHeight, // 屏幕高
      orderactive: 0, // 订单状态
      order: [[], []], // 订单列表
      loading: [true, true],
      finished: [false, false],
      currSize: 20, // 每次查询条数
      currOffset: [0, 0], // 查询到哪里了
      list: [{}], // 展示内容
      status: 6, // 订单状态
      shareid: 0,
      merchid: 0,
      strId: "", // 水印
      userinfo: {},
      showkefu: 0, 
      showAddinfo: false,
      rexpresssn: '',
      orderone: {}, // 正在操作的的订单
    };
  },
  mounted() {
    this.strId = localStorage.getItem("DK_STRID");
    if (this.$route.query.shareid) {
      this.shareid = this.$route.query.shareid
    }else if (localStorage.getItem("DK_MEMBERID")) {
      this.shareid = localStorage.getItem("DK_MEMBERID");
    }
    
    this.merchid = this.$route.query.merchid ? this.$route.query.merchid : 0;
    this.$parent.getmerchname(this.merchid, "我的订单");
  },
  activated() {
    
    if (parseInt(this.$route.query.status) == 7) {
      this.orderactive = 0;
    }else if(parseInt(this.$route.query.status) == 6) {
      this.orderactive = 1;
    }
    this.status = parseInt(this.$route.query.status);

    this.loading[this.orderactive] = true;
    this.currOffset[this.orderactive] = 0;
    this.init();
    setTimeout(() => {
      this.showkefu = localStorage.getItem("DK_SHOWKEFU");
    }, 500);
  },
  methods: {
    init() {
      this.getOrderList();
      this.getuserinfo();
    },
    onClickLeft() {
      this.$router.go(-1);
    },
    // 获取用户信息
    getuserinfo() {
      let _that = null;
      _that = this;
      _that.$axios.defaults.headers.common['Authorization'] = localStorage.getItem("DK_UID");
      _that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
      _that.$axios
        .post(_that.$store.state.domain + "web/user/getinfor")
        .then(function(response) {
          if (response.data && response.data.id > 0) {
            _that.userinfo = response.data;
          }
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    getOrderList() {
      let _that = null;
      _that = this;
      let UID = null;
      UID = localStorage.getItem("DK_UID"); // 用户身份公钥
      _that.$axios.defaults.headers.common["Authorization"] = UID;
      _that.$axios
        .post(
          _that.$store.state.domain + "web/order/get_order",
          _that.$qs.stringify({
            status: _that.status,
            size: _that.currSize,
            offset: _that.currOffset[_that.orderactive],
            merchid: _that.merchid
          })
        )
        .then(response => {
          _that.loading[_that.orderactive] = false;
          if (response.data.code == 100000) {
            _that.order[_that.orderactive] = response.data.data;
            _that.list = response.data.data;
            if (response.data.data.length >= _that.currSize) {
              _that.currOffset[_that.orderactive] = _that.currSize;
              _that.finished[_that.orderactive] = false;
            } else {
              _that.currOffset[_that.orderactive] = response.data.data.length;
              _that.finished[_that.orderactive] = true;
            }
          } else {
            _that.finished[_that.orderactive] = true;
            _that.list = [];
          }
        })
        .catch(error => {
          _that.loading[_that.orderactive] = false;
          _that.finished[_that.orderactive] = true;
          console.log(error);
        });
    },
    // 滚动加载
    onLoad() {
      let _that = null;
      _that = this;
      let UID = null;
      UID = localStorage.getItem("DK_UID"); // 用户身份公钥
      _that.$axios.defaults.headers.common["Authorization"] = UID;
      _that.$axios
        .post(
          _that.$store.state.domain + "web/order/get_order",
          _that.$qs.stringify({
            status: _that.status,
            size: _that.currSize,
            offset: _that.currOffset[_that.orderactive],
            merchid: _that.merchid
          })
        )
        .then(response => {
          console.log(response);
          console.log("load", _that.orderactive);
          _that.loading[_that.orderactive] = false;
          if (response.data.code == 100000) {
            if (_that.order[_that.orderactive]) {
              response.data.data.forEach(item => {
                _that.list.push(item);
              });
            } else {
              _that.order[_that.orderactive] = response.data.data;
              _that.list = response.data.data;
            }

            if (response.data.data.length >= _that.currSize) {
              _that.currOffset[_that.orderactive] += _that.currSize;
              _that.finished[_that.orderactive] = false;
            } else {
              _that.currOffset[_that.orderactive] += response.data.data.length;
              _that.finished[_that.orderactive] = true;
            }
          } else {
            _that.finished[_that.orderactive] = true;
          }
        })
        .catch(error => {
          _that.loading[_that.orderactive] = false;
          _that.finished[_that.orderactive] = true;
          console.log(error);
        });
    },
    // 切换标签页
    tabsClick(name, title) {
      console.log(name, title);
      this.orderactive = name;

      if (name==0) {
        this.status = 7;
      }else{
        this.status = 6;
      }
      this.$router.replace({ name: "OrderTwoList", query: { status: this.status, shareid: this.shareid, merchid: this.merchid, _t: 3 } });
      if (this.order[name].length <= 0 && !this.finished[name]) {
        this.getOrderList();
      } else {
        this.list = this.order[name];
      }
    },
    // 查看订单详情
    details(index) {
      let status = null;
      status = this.status;
      let id = 0;
      id = this.list[index].id;
      let title = "";
      if (status == 0) {
        title = "待付款";
      } else if (status == 1) {
        title = "待发货";
      } else if (status == 2) {
        title = "待收货";
      } else if (status == 3) {
        title = "已完成";
      } else if(status == 5) {
        title = "驳回";
      } else if(status == 6) {
        title = "售后";
      }

      this.$router.push({
        name: "OrderDetails",
        query: { title: title, status: status, id: id, shareid: this.shareid, merchid: this.merchid, _t: 3 }
      });
    },
    // 去支付
    toPay(id, index) {
      console.log("去支付", id, index, this.list[index]);
      let uid = 0; // 用户id
      let ordersn = ""; // 订单编号
      let money = 0; // 订单金额
      let orderid = 0; // 订单id
      uid = this.list[index].memberid;
      ordersn = this.list[index].ordersn;
      money = this.list[index].price;
      orderid = this.list[index].id;
      localStorage.setItem("ordersn", ordersn);
      localStorage.setItem("money", money);
      localStorage.setItem("orderid", orderid);
      this.$router.push({name: "CheckPayment", query: {shareid: this.shareid, merchid: this.merchid} });
    },
    // 取消订单
    cancelPay(id, index) {
      console.log(id, index);
      let _that = null;
      _that = this;
      this.$dialog.alert({
        title: "关闭订单",
        message: "确定要关闭订单?",
        showCancelButton: true,
        beforeClose: function(action, done) {
          done();
          if (action == "confirm") {
            _that.$axios.defaults.headers.common["Authorization"] = localStorage.getItem("DK_UID");
            _that.$axios
              .post(
                _that.$store.state.domain + "web/order/cancel",
                _that.$qs.stringify({
                  id: id
                })
              )
              .then(response => {
                console.log(response);
                if (response && response.data && response.data.code == 100000) {
                  console.log("取消订单成功");
                  _that.$toast(response.data.msg);
                  _that.list.splice(index, 1);
                }
              })
              .catch(error => {
                console.log(error);
              });
          }
          if (action == "cancel") {
            console.log("取消按钮");
          }
        }
      });
    },
    // 复制快递单号
    expressnonCopy() {
      this.$toast("复制成功");
    },
    // 快递单号复制失败
    expressnonError() {
      this.$toast("复制失败");
    },
    // 复制订单号
    ordersnonCopy() {
      this.$toast("复制成功");
    },
    // 复制订单号
    ordersnError() {
      this.$toast("复制失败");
    },

    // 退款记录
    reFundHistory(id) {
      this.$router.push({
        name: "refundHistory",
        query: {
          id: id,
          shareid: this.shareid,
          merchid: this.merchid
        }
      });
    },

    // 申请退款
    miniTuikuan(index) {
      let order = {};
      order = this.list[index];
      if(order.is_saleservice > 0 && order.is_saleservice != 2) { // 退款中
        this.$router.push({
          name: "serviceDetail",
          query: {
            id: order.id,
            shareid: this.shareid,
            merchid: this.merchid
          } 
        });
      }else { // 去退款
        this.$router.push({
          name: "miniTuikuan",
          query: {
            id: order.id,
            shareid: this.shareid,
            merchid: this.merchid
          }
        });
      }
      
    },
    
    // 申请退款按钮
    reFund(index) {
      console.log(index);
      this.$router.push({
        name: "OrderRefund",
        query: {
          id: this.list[index].id,
          money: this.list[index].price,
          status: this.list[index].status,
          shareid: this.shareid,
          merchid: this.merchid
        } 
      });
    },
    // 撤销退款
    cancelRefund(index) {
      let _that = null;
      _that = this;
      _that.$router.push({
        name: "serviceDetail",
        query: {
          id: this.list[index].id,
          shareid: this.shareid,
          merchid: this.merchid
        } 
      });
    },
    // 七鱼客服
    qiyukefu(index) {
      let _that = null;
      _that = this;
      let order = {};
      order = _that.list[index];

      if ((_that.merchid == 0 || this.showkefu == 1)) {
        let uid = 0;
        let name = "未登录—游客";
        let agentCode = "";
        let level = 1;
        let avatar = "";
        let levelname = "普通会员";
        let mobile = "";
        if (_that.userinfo && _that.userinfo.id > 0) {
          if (_that.userinfo.level == '6') {
            level = 2;
          }else if (_that.userinfo.level == '1') {
            level = 3;
          }else if (_that.userinfo.level == '14') {
            level = 4;
          }else if (_that.userinfo.level == '5') {
            level = 5;
          }
          uid = _that.userinfo.id;
          name = _that.userinfo.nickname;
          agentCode = _that.userinfo.agent_code;
          avatar = _that.userinfo.avatar;
          if (_that.userinfo.levelname) {
            levelname = _that.userinfo.levelname;
          }
          mobile = _that.userinfo.mobile;
        }else{
          _that.$toast("请先登录");
          return false;
        }

        if(_that.userinfo.id > 0) {
          let str = "";
          let desc = "";
          str = JSON.stringify([
            {"index":0, "key":"avatar","label":"头像","value":avatar},
            {"index":1, "key":"agent_code", "label":"邀请码", "value":agentCode},
            {"index":2, "key":"level", "label":"等级", "value":levelname},
            {"index":3, "key":"shopname", "label":"商户名称", "value":localStorage.getItem("DK_SHOPNAME")},
            {"index":4, "key":"mobile", "label":"手机号", "value":mobile},
            {"index":5, "key":"shopid", "label":"商户id", "value":_that.merchid},
            {"index":6, "key":"shareid", "label":"分享者id", "value":_that.shareid}
          ]);
          desc = JSON.stringify([{title: order.ordersn + "【" + order.surplus.time_str + "】"}, {picture: order.goods[0].goodsthumb}]);
          window.location.href = "https://kefu.midbest.cn/wap/?uid=" + _that.userinfo.id + "&sessionform=" + str + "&card=" + desc + "&fromType=2";
          return false;
        }
      }else{
        this.$toast("页面还未加载完，稍等一下");
      }
    },
    // 点击回复售后按钮
    addserviceInfo(index) {
      let order = null;
      order = this.list[index];
      this.orderone = order;
      this.orderone.index = index;
      this.$router.push({
        name: "serviceDetail",
        query: {
          id: order.id,
          shareid: this.shareid,
          merchid: this.merchid
        } 
      });
    },

    serviceDetail(id) {
      this.$router.push({
        name: "serviceDetail",
        query: {
          id: id,
          shareid: this.shareid,
          merchid: this.merchid
        } 
      });
    },

    btnConfirm() {
      this.showAddinfo = false;
      let that = null;
      that = this;
      that.$toast({
        type: 'loading',
        message: '加载中...',
        overlay: true
      })

      that.$axios.defaults.headers.common['Authorization'] = localStorage.getItem("DK_UID");
      that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
      that.$axios
        .post(
          that.$store.state.domain + "web/order/add_rexpress",
          that.$qs.stringify({
            express: that.rexpresssn,
            id: that.orderone.id
          })
        )
        .then(function(res) {
          if (res.data.code == 100000) {
            that.$toast(res.data.msg ? res.data.msg : '添加完成');
            that.order[that.orderactive][that.orderone.index].progress = 4;
            that.order[that.orderactive][that.orderone.index]['is_saleservice_content'] = "寄回单号："+that.rexpresssn;

            that.list[that.orderone.index].progress = 4;
            that.list[that.orderone.index]['is_saleservice_content'] = "寄回单号："+that.rexpresssn;
          }else {
            that.$toast(res.data.msg ? res.data.msg : '添加失败');
          }
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    btnCancel() {
      this.showAddinfo = false;
    }
  }
};
</script>
<style lang="less">
.orderTwolist {
  background-color: #ededed;
  width: 100%;
  min-height: 100%;
  padding-bottom: 50px;
  .van-nav-bar {
    .van-nav-bar__left {
      .van-icon {
        color: #333;
      }
    }
  }
  .main {
    .order_list {
      margin-top: 10px;
      .van-cell {
        padding: 10px;
        .van-cell__title {
          flex: 1;
          text-align: left;
        }
        .van-button {
          margin-left: 10px;
        }
      }
      .van-cell::after {
        left: 0;
        right: 0;
      }
      .van-card {
        padding: 10px;
        text-align: left;
        margin-top: 0;
        background-color: #fff;
        .van-card__thumb img {
          border-radius: 4px;
        }
        .van-card__tag {
          top: inherit;
          left: inherit;
          bottom: 0;
          right: 0;
          background-color: #fff;
          color: #808080;
          font-size: 14px;
          line-height: 16px;
          padding: 0 2px;
          opacity: 0.5;
        }
      }
    }
    .copay_icon .van-tag {
      margin-left: 5px;
      line-height: 20px;
      height: 20px;
      padding: 0 10px;
    }
    .red {
      color: #ee0a24;
    }
  }
  .input_main {
    .van-cell {
      .van-cell__value {
        flex: 1;
      }
    }
  }
}
</style>